<template>
  <div>
    <div class='row'>
      <div class='col-lg-6 col-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <vb-headers-card-header :data="{ title: 'Reklam Yönetimi' }" />
          </div>

          <div class='card-body'>
            <image-resizer
              :loading='loading'
              :on-complete='beforeUpload'
              :initial-image='coverImage'
              :max-size='1400'
              :error-text='"Fotoğraf en az 1400x1000 piksel ölçülerinde olmalıdır."'
            />

            <a-form
              label-align='left'
              layout='vertical'
            >
              <a-form-item label='Reklam Sayfası Başlığı' name='advPageTitle'>
                <a-input placeholder='Başlık' v-model:value='advPageTitle' />
              </a-form-item>
              <a-form-item label='Reklam İçeriği' name='advContent'>
                <a-input placeholder='Madde 1' v-model:value='advContent1' />
              </a-form-item>
              <a-form-item name='advContent2'>
                <a-input placeholder='Madde 2' v-model:value='advContent2' />
              </a-form-item>
              <a-form-item name='advContent3'>
                <a-input placeholder='Madde 3' v-model:value='advContent3' />
              </a-form-item>
              <a-form-item name='advContent4'>
                <a-input placeholder='Madde 4' v-model:value='advContent4' />
              </a-form-item>
              <a-form-item name='advContent5'>
                <a-input placeholder='Madde 5' v-model:value='advContent5' />
              </a-form-item>
              <div class='row'>
                <div class='col col-md-12 col-lg-6 justify-content-center'>
                  <a-button :loading='loading' @click='handleFinish' class='btn btn-success px-5'>Güncelle
                  </a-button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
      <div class='col-lg-6 col-md-12'></div>
    </div>
  </div>
</template>

<script>
import { Advertise, AdvertiseAdministration } from '../../services/api-service'
import { notification } from 'ant-design-vue'

export default {
  name: 'VbReklamYNetimi',
  data() {
    const handleFinish = async () => {
      this.loading = true

      const lineContents = []

      const lineContentPush = (line) => {
        if (typeof line !== 'undefined' && line !== '') {
          lineContents.push(line)
        }
      }

      lineContentPush(this.advContent1)
      lineContentPush(this.advContent2)
      lineContentPush(this.advContent3)
      lineContentPush(this.advContent4)
      lineContentPush(this.advContent5)

      if (lineContents.length > 0) {
        const contentJsonData = {
          title: this.advPageTitle,
          lineContents,
        }

        try {
          const { data } = await AdvertiseAdministration.updateAdvertise({
            coverPhoto: this.advertiseCoverPhoto !== this.coverImage ? this.coverImage : undefined,
            contentJsonData: contentJsonData,
          })
          if (data && this.coverImage !== this.advertiseCoverPhoto) {
            this.advertiseCoverPhoto = data.updatedPhoto.coverImage1X
          }

          notification.success({
            message: 'Başarıyla Güncellendi',
          })
        } catch {
        }
      } else {
        notification.error({
          message: 'Lütfen en az 1 içerik yazınız.',
        })
      }

      this.loading = false
    }

    return {
      loading: false,
      coverImage: '',
      handleFinish,
      advertise: '',
      advertiseCoverPhoto: '',
      advPageTitle: '',
      advContent1: undefined,
      advContent2: undefined,
      advContent3: undefined,
      advContent4: undefined,
      advContent5: undefined,
    }
  },
  mounted() {
    this.loadAdvertise()
  },
  methods: {
    beforeUpload(file) {
      this.coverImage = file
    },
    async loadAdvertise() {
      this.loading = true
      try {
        const { data } = await Advertise.getCurrentAdvertise()
        if (data && data.contentJsonData) {
          this.advertiseCoverPhoto = data.coverPhoto.coverImage1X
          this.coverImage = data.coverPhoto.coverImage1X

          const jsonData = JSON.parse(data.contentJsonData)
          this.advPageTitle = jsonData.Title
          this.advContent1 = jsonData.LineContents[0]
          this.advContent2 = jsonData.LineContents[1]
          this.advContent3 = jsonData.LineContents[2]
          this.advContent4 = jsonData.LineContents[3]
          this.advContent5 = jsonData.LineContents[4]
        }
      } catch {
        notification.error({
          message: 'Lütfen Reklam Engelleyicinizi Kapatıp Sayfayı Yenileyiniz.',
        })
      }
      this.loading = false
    },
  },
}
</script>
